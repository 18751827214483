
import { Component, Emit, Model, Prop, Vue, Watch } from "vue-property-decorator";
import Rules from "@/app/modules/_global/classes/Rules";

@Component
export default class DatePicker extends Vue {
  @Prop(Object) readonly field?: Record<string, unknown>;
  @Prop(String) readonly label?: string;

  @Model("input", { type: String }) readonly data!: string;

  @Emit()
  input() {
    return this.localData;
  }

  @Watch("localData")
  onLocalDataChanged() {
    this.input();
  }

  localData: string;

  rules = {
    required: this.field?.required ? Rules.required : true
  };

  constructor() {
    super();

    this.localData = this.data;
  }
}
